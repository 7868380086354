const requiredEnvVariable = (key: string): string => {
  const value = import.meta.env[key];
  if (value) return value;

  throw new Error(`Missing required environment variable '${key}'`);
};

const requiredStrings = [
  'VITE_LD_CLIENT_ID',
  'VITE_DD_SERVICE',
  'VITE_DD_ENV',
  'VITE_DD_VERSION',
] as const;
const optionalStrings = ['SENTRY_AUTH_TOKEN', 'VITE_SENTRY_DSN'] as const;

const strings = {
  ...requiredStrings.reduce((obj: StringConfig, key) => {
    obj[key] = requiredEnvVariable(key);
    return obj;
  }, {} as any),
  ...optionalStrings.reduce((obj: StringConfig, key) => {
    obj[key] = import.meta.env[key];
    return obj;
  }, {} as any),
};

type StringConfig = {
  [key in (
    | typeof requiredStrings
    | typeof optionalStrings
  )[number]]: key extends typeof optionalStrings[number]
    ? string | undefined
    : string;
};

const config: StringConfig = {
  ...strings,
};

export default config;
