import {
  TherapyType,
  ProviderLicenseDescription,
  Role,
  WillingToSee,
} from './types';

export const supportEmail = 'support@rula.com';
export const supportEmailPsych = 'psychsupport@rula.com';
export const surveysUrl = 'https://surveys.rula.com';
export const whenToSeePsychLink =
  'https://www.rula.com/blog/when-to-see-a-psychiatrist/';
export const typesOfTherapyLink =
  'https://www.rula.com/blog/therapy-types-individual-couples-family/';
export const kaiserSoCalLink =
  'https://healthy.kaiserpermanente.org/southern-california/health-wellness/mental-health/services';
export const kaiserHawaiiLink =
  'https://healthy.kaiserpermanente.org/hawaii/health-wellness/mental-health/services';
export const PROVIDER_BIO_MAX_LENGTH = 250;
export const providerLicenses = {
  md: {
    name: 'Psychiatrist',
  },
  do: {
    name: 'Psychiatrist',
  },
  psyd: {
    name: 'Licensed Psychologist',
  },
  phd: {
    name: 'Licensed Psychologist',
  },
  cp: {
    name: 'Licensed Psychologist',
  },
  lp: {
    name: 'Licensed Psychologist',
  },
  np: {
    name: 'Psychiatric Mental Health Nurse Practitioner',
  },
  licsw: {
    name: 'Licensed Independent Clinical Social Worker',
  },
  lisw: {
    name: 'Licensed Independent Social Worker',
  },
  lcsw: {
    name: 'Licensed Clinical Social Worker',
  },
  lcpc: {
    name: 'Licensed Clinical Professional Counselor',
  },
  lpcc: {
    name: 'Licensed Professional Clinical Counselor',
  },
  lpc: {
    name: 'Licensed Professional Counselor',
  },
  lmft: {
    name: 'Licensed Marriage and Family Therapist',
  },
  limft: {
    name: 'Licensed Independent Marriage and Family Therapist',
  },
  lmhc: {
    name: 'Licensed Mental Health Counselor',
  },
  lcsws: {
    name: 'Licensed Clincial Social Worker- Supervisor',
  },
  lcp: {
    name: 'Licensed Clinical Psychologist',
  },
  lep: {
    name: 'Licensed Educational Psychologist',
  },
  lcmhc: {
    name: 'Licensed Clinical Mental Health Counselor',
  },
} as const;

export const therapyTypeHeader: Record<TherapyType, string> = {
  individual: 'Individual Therapist',
  couples: 'Couples Therapist',
  family: 'Family Therapist',
  psychiatric: 'Psychiatry Provider',
} as const;

export const therapyTypeToVisitType: Record<TherapyType, string> = {
  individual: 'Individual therapy',
  couples: 'Couples therapy',
  family: 'Family therapy',
  psychiatric: 'Psychiatry',
} as const;

export const therapyTypeToAppointmentType: Record<TherapyType, string> = {
  individual: 'Individual',
  couples: 'Couples',
  family: 'Family',
  psychiatric: 'Psychiatry',
} as const;

export const therapyTypeToRole: Record<TherapyType, Role> = {
  individual: 'THERAPIST',
  couples: 'THERAPIST',
  family: 'THERAPIST',
  psychiatric: 'PRESCRIBER',
};

export const AGE_BANDS = {
  ADULT: {
    MIN: 18,
    MAX: 999,
  },
  TEEN: {
    MIN: 13,
    MAX: 17,
  },
  PRETEEN: {
    MIN: 8,
    MAX: 12,
  },
  CHILD: {
    MIN: 5,
    MAX: 7,
  },
};

export const getWillingToSeeFromTherapyType = (
  therapyType: TherapyType,
  age: number
): WillingToSee => {
  switch (therapyType) {
    case 'couples':
      return 'Couples';
    case 'family':
      return 'Families';
    case 'psychiatric':
    case 'individual':
    default:
      if (age <= AGE_BANDS.CHILD.MAX) {
        return 'Children (5-7)';
      } else if (age <= AGE_BANDS.PRETEEN.MAX) {
        return 'Preteen (8-12)';
      } else if (age <= AGE_BANDS.TEEN.MAX) {
        return 'Minors (13-17)';
      }
      return 'Individuals';
  }
};

export const therapyTypeToDescription: Record<TherapyType, string> = {
  individual:
    'One-on-one counseling with a licensed therapist focused on your unique concerns.',
  couples:
    'Meet as a couple for relationship support from a specialized, licensed mental health expert.',
  family:
    'Work with all or specific members of the family to improve communication and reduce conflict.',
  psychiatric:
    'Meet with a licensed psychiatric provider who can diagnose conditions, prescribe medication, and provide talk therapy.',
};

export const therapyTypes: TherapyType[] = [
  'couples',
  'family',
  'individual',
  'psychiatric',
];

export const providerProfileSections = [
  {
    label: '',
    key: 'profile_summary',
    required: true,
  },
  {
    label: 'My approach',
    key: 'profile_approach',
    required: true,
  },
  {
    label: 'My focus',
    key: 'profile_focus',
    required: true,
  },
  {
    label: 'My communication style',
    key: 'profile_style',
    required: true,
  },
  {
    label: 'My journey to therapy',
    key: 'profile_journey',
    required: false,
  },
  {
    label: 'My goals for you',
    key: 'profile_goals',
    required: false,
  },
  {
    label: 'My first session with you',
    key: 'profile_first_session',
    required: false,
  },
] as const;

export const willingToSeeToTherapyType: Record<string, string> = {
  Individuals: 'Individual Therapy',
  Couples: 'Couples Therapy',
  Families: 'Family Therapy',
  'Minors (13-17)': 'Adolescents/Teens',
  'Preteen (8-12)': 'Preteens (8-12)',
  'Children (5-7)': 'Children (5-7)',
};

export const psychiatryProviderRole = 'PRESCRIBER';
export const therapyProviderRole = 'THERAPIST';

// Rematch and reschedule errors
export const errorsFindingOldAppointment = [
  'Failed to fetch existing events',
  'Could not find old event with given unique ID',
];
export const errorsCancelingHold = [
  'Error cancelling event',
  'Failed to cancel',
];
export const errorSlotNotAvailable = 'Unable to book the selected time';
export const errorCreatingHold = 'Failed to create new hold';
export const errorRematchSameProvider = "Can't rematch to same provider";
export const errorNotWillingToSee = 'New provider not willing to see';

// Toasts for rematch and reschedule errors
export const toastSessionNotAvailable =
  'The session you chose is no longer available. Please choose another session.';
export const toastDidntWorkTryAgain =
  "We're sorry, but that didn't work. Please try again.";
export const toastErrorBookingSession =
  'There was an error booking your session. Please try again.';

// Should be kept up to date with PROVIDER_LICENSE_INFOMATION in https://github.com/pathccm/self_scheduling_frontend/blob/main/app/services/constants.rb#L347
// The source of truth for both SSFE and patient_portal for this date is: https://docs.google.com/spreadsheets/d/1pbPdYO0TW_x7ZtCUYAQ94HR9y8s7H9mAlsqKeBr74zc/edit#gid=0
// TODO - find a way to share constant data instead of hoping we keep it in sync
export const providerLicenseDescriptions: Record<
  string,
  ProviderLicenseDescription
> = {
  MD: {
    name: 'Psychiatrist',
    priority: 1,
    description:
      'Our providers are specifically licensed to treat mental health. They are able to assess patient needs, order and interpret diagnostic and laboratory tests, diagnose disease, formulate and prescribe medications and treatment plans.',
  },
  DO: {
    name: 'Psychiatrist',
    priority: 1,
    description:
      'Our providers are specifically licensed to treat mental health. They are able to assess patient needs, order and interpret diagnostic and laboratory tests, diagnose disease, formulate and prescribe medications and treatment plans.',
  },
  PSYD: {
    name: 'Licensed Psychologist',
    priority: 2,
    description:
      'Licensed Psychologists, LPs, hold a doctoral degree and are trained to diagnose and treat mental health issues and substance misuse with psychotherapy and counseling.',
  },
  PHD: {
    name: 'Licensed Psychologist',
    priority: 3,
    description:
      'Licensed Psychologists, LPs, hold a doctoral degree and are trained to diagnose and treat mental health issues and substance misuse with psychotherapy and counseling.',
  },
  CP: {
    name: 'Licensed Psychologist',
    priority: 4,
    description:
      'Licensed Psychologists, LPs, hold a doctoral degree and are trained to diagnose and treat mental health issues and substance misuse with psychotherapy and counseling.',
  },
  LP: {
    name: 'Licensed Psychologist',
    priority: 5,
    description:
      'Licensed Psychologists, LPs, hold a doctoral degree and are trained to diagnose and treat mental health issues and substance misuse with psychotherapy and counseling.',
  },
  NP: {
    name: 'Psychiatric Mental Health Nurse Practitioner',
    priority: 6,
    description:
      'Our providers are specifically licensed to treat mental health. They are able to assess patient needs, order and interpret diagnostic and laboratory tests, diagnose disease, formulate and prescribe medications and treatment plans.',
  },
  LICSW: {
    name: 'Licensed Independent Clinical Social Worker',
    priority: 7,
    description:
      'Licensed Independent Clinical Social Workers, LICSWs, are social workers trained to diagnose and treat mental health issues and substance misuse with psychotherapy and counseling.',
  },
  LISW: {
    name: 'Licensed Independent Social Worker',
    priority: 8,
    description:
      'Licensed Independent Social Workers, LISWs, are social workers trained to provide psychotherapy and counseling.',
  },
  LCSW: {
    name: 'Licensed Clinical Social Worker',
    priority: 9,
    description:
      'Licensed Clinical Social Workers, LCSWs, are social workers trained to diagnose and treat mental health issues and substance misuse with psychotherapy and counseling.',
  },
  LCPC: {
    name: 'Licensed Clinical Professional Counselor',
    priority: 10,
    description:
      'Licensed Clinical Professional Counselors, LCPCs, are mental health professionals trained to diagnose and treat mental health issues and substance misuse with psychotherapy and counseling.',
  },
  LPCC: {
    name: 'Licensed Professional Clinical Counselor',
    priority: 11,
    description:
      'Licensed Professional Clinical Counselors, LPCCs, are mental health professionals trained to diagnose and treat mental health issues and substance misuse with psychotherapy and counseling.',
  },
  LPC: {
    name: 'Licensed Professional Counselor',
    priority: 12,
    description:
      'Licensed Professional Counselors, LPCs, are mental health professionals trained to diagnose and treat mental health issues and substance misuse with psychotherapy and counseling.',
  },
  LMFT: {
    name: 'Licensed Marriage and Family Therapist',
    priority: 13,
    description:
      'Licensed Marriage and Family Therapists, LMFTs, are mental health professionals trained in family systems who can diagnose and treat mental health issues and substance misuse with psychotherapy and counseling.',
  },
  LIMFT: {
    name: 'Licensed Independent Marriage and Family Therapist',
    priority: 14,
    description:
      'Licensed Independent Marriage and Family Therapists, LIMFTs, are mental health professionals trained in family systems who can diagnose and treat mental health issues and substance misuse with psychotherapy and counseling.',
  },
  LMHC: {
    name: 'Licensed Mental Health Counselor',
    priority: 15,
    description:
      'Licensed Mental Health Counselors, LMHCs, are mental health professionals trained to diagnose and treat mental health issues and substance misuse with psychotherapy and counseling.',
  },
  LCSWS: {
    name: 'Licensed Clinical Social Worker - Supervisor',
    priority: 16,
    description:
      'Licensed Clinical Social Worker Supervisors, LCSWSs, are social workers trained to provide psychotherapy and supervise other providers.',
  },
  LCP: {
    name: 'Licensed Clinical Psychologist',
    priority: 17,
    description:
      'Licensed Clinical Psychologists, LCPs, hold a doctoral degree and are trained to diagnose and treat mental health issues and substance misuse with psychotherapy and counseling.',
  },
  LEP: {
    name: 'Licensed Educational Psychologist',
    priority: 18,
    description:
      'Licensed Educational Psychologists, LEPs, are trained to work in academic settings to provide psyotherapy and care related to academic performance.',
  },
  LCMHC: {
    name: 'Licensed Clinical Mental Health Counselor',
    priority: 19,
    description:
      'Licensed Clinical Mental Health Counselors, LCMHCs, are mental health professionals trained to diagnose and treat mental health issues and substance misuse with psychotherapy and counseling.',
  },
};

export const stringsToAllCaps: string[] = [
  'adhd',
  'asl',
  'lgbtq+',
  'lgbtqia+',
  'mhn',
  'ptsd',
];

export const stringsToLowercase: string[] = ['or', 'and'];

export const usStateAbbreviations: string[] = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const timeSlots = {
  Morning: {
    start: 6,
    end: 12,
  },
  Afternoon: {
    start: 12,
    end: 16,
  },
  Evening: {
    start: 16,
    end: 21,
  },
} as const;

export const daysOfWeek = ['M', 'T', 'W', 'Th', 'F', 'Sa', 'Su'] as const;

export const dayLabels = {
  M: 'Monday',
  T: 'Tuesday',
  W: 'Wednesday',
  Th: 'Thursday',
  F: 'Friday',
  Sa: 'Saturday',
  Su: 'Sunday',
} as const;

export const customInsuranceCapitalizationMap: Record<string, string> = {
  'blue shield of ca': 'Blue Shield of CA',
} as const;

export const HELP_CENTER_LINK =
  'https://patientsupport.rula.com/hc/en-us' as const;

export const ADD_NEW_SERVICE_LINK =
  'https://www.rula.com/add-services/?utm_source=patient_app' as const;

export const MODALITY_TOOLTIPS = {
  Directive:
    'Short term, goal oriented approaches often used to help people change unhealthy thoughts, feelings, and behaviors.',
  'Insight-oriented':
    'Therapeutic approaches where the therapist creates a supportive environment for the client to explore their identity, experiences, feelings and emotions.',
  Relational: 'Approaches that focus on improving interpersonal relationships.',
  'Couples/Family':
    'Treatment that involves couples or families working to improve relationships.',
  Psychodynamic:
    "Emphasizes how past and present life events and relationships impact one's current relationships, emotional reactions and life choices.",
} as const;

export const getAmdPatientPortalLink = (officeKey: number) => {
  return `https://patientportal.advancedmd.com/${officeKey}/account/logon`;
};
