import { useContext, useMemo, useState } from 'react';
import { StateContext } from '../../App';
import CreditCard from '../../components/icons/CreditCard';
import TextInput, { CreditCardInput } from '../../components/TextInput';
import Text from '../../components/Text';
import actions from '../../state/actions';
import {
  Address,
  AuthenticatedState,
  CreditCardDetails as TCreditCardDetails,
  Data,
  isEditing,
  JSONValue,
  PatientData,
} from '../../utils/types';
import EditSection from './EditSection';
import ReadSection from './ReadSection';
import fetch from '../../utils/fetch';
import setDefaults from '../../utils/set-defaults';
import Checkbox from '../../components/Checkbox';
import useValidation, {
  makeErrorState,
  ValidationError,
} from '../../utils/use-validation';
import { CardNumberVerification } from 'card-validator/dist/card-number';
import dayjs from 'dayjs';
import Dropdown from '../../components/Dropdown';
import { changeOnlyIfValid } from '../../utils/on-change';
import { match } from '../../utils/validations';
import DateInput from '../../components/DateInput';
import { usStateAbbreviations } from '../../utils/constants';

export interface CreditCardProps {
  creditCard: Extract<
    AuthenticatedState['data']['creditCard'],
    { loadingState: 'done' }
  >;
  patientData: PatientData;
  homeAddress: Address;
  setEditing: (editing: boolean) => unknown;
}

const Read = ({
  card_brand,
  last_4_digits,
  edit,
}: TCreditCardDetails & { edit: () => unknown }) => {
  const { dispatch } = useContext(StateContext);
  const data = last_4_digits ? (
    `${card_brand ? `${card_brand} ending in ` : ''}${last_4_digits}`
  ) : (
    <a onClick={edit} className="no-underline text-primary-4">
      Add a credit card
    </a>
  );
  return (
    <ReadSection
      label="Payment method"
      editLabel={last_4_digits ? 'Replace card' : 'Edit'}
      onEditClick={() => dispatch(actions.profile.setCcEditMode('edit'))}
    >
      <ReadSection.Row Icon={CreditCard} data={data} />
    </ReadSection>
  );
};

const Edit = ({
  editState,
  patientData,
  homeAddress,
}: Extract<Data['creditCard'], { editState: { mode: 'edit' } }> & {
  patientData: PatientData;
  homeAddress: Address;
}) => {
  const { edited, persistence, invalid } = editState;
  const {
    first_name,
    last_name,
    number,
    expiration_date,
    cvv,
    billing_address,
  } = edited;
  const { state, dispatch } = useContext(StateContext);
  const [useHomeForBilling, setUseHomeForBilling] = useState(
    homeAddress && !!homeAddress.street
  );
  const banner = state.ui.banner;

  const [cardValidation, setCardValidation] =
    useState<CardNumberVerification>();

  const [expiration_month, expiration_year] = (expiration_date || '').split(
    '/'
  );

  const rawNumber = number?.replace(/ /g, '');

  const brand = useMemo(() => {
    return cardValidation?.card?.niceType;
  }, [cardValidation]);

  const validation = useValidation(() =>
    dispatch(actions.profile.setInvalid({ invalid: false, key: 'creditCard' }))
  )(() => {
    const validations: ValidationError[] = [];
    if (
      !rawNumber ||
      !cardValidation ||
      !cardValidation.isPotentiallyValid ||
      !cardValidation.card?.lengths.includes(rawNumber?.length)
    ) {
      validations.push({
        message: 'Please enter a valid credit card number',
        keys: ['number'],
      });
    }
    if (
      brand &&
      !['Visa', 'Mastercard', 'American Express', 'Discover'].includes(brand)
    ) {
      validations.push({
        message:
          'Please use a different card. We only accept Visa, Mastercard, Discover, and American Express.',
        keys: ['number'],
      });
    }
    if (
      !cvv ||
      (cardValidation && cardValidation.card?.code.size !== cvv.length)
    ) {
      validations.push({
        message: `Please enter a valid CVV`,
        keys: ['cvv'],
      });
    }
    if (!first_name) {
      validations.push({
        message: "Please enter the cardholder's first name",
        keys: ['first_name'],
      });
    }
    if (!last_name) {
      validations.push({
        message: "Please enter the cardholder's last name",
        keys: ['last_name'],
      });
    }
    if (
      !expiration_year ||
      !expiration_month ||
      dayjs().isAfter(`20${expiration_year}-${expiration_month}-01`)
    ) {
      validations.push({
        keys: ['expiration_date'],
        message: 'Please enter an expiration date in the future',
      });
    }
    if (!useHomeForBilling) {
      const missingBillingAddressKeys = [
        'street',
        'state',
        'city',
        'zip',
      ].filter((k) => !billing_address[k]);
      if (missingBillingAddressKeys.length) {
        validations.push({
          keys: missingBillingAddressKeys,
          message: 'Please enter a valid billing address',
        });
      }
    }
    return validations;
  }, [
    number,
    first_name,
    last_name,
    expiration_month,
    expiration_year,
    cvv,
    useHomeForBilling,
    billing_address,
  ]);

  const validationErrors = validation.map(({ message }) => message);

  const onSave = () => {
    () => dispatch(actions.setToast(null)); // clear any existing toasts
    if (validation.length) {
      dispatch(
        actions.profile.setInvalid({ key: 'creditCard', invalid: true })
      );
      return false;
    }
    dispatch(actions.profile.setCcDetailsPersistence('saving'));
    const address = useHomeForBilling ? homeAddress : billing_address;
    const { patient_record_uuid } = patientData;

    return fetch
      .json('/api/update_credit_card', {
        method: 'PUT',
        body: {
          patientIdentifiers: {
            patient_record_uuid: patient_record_uuid,
          },
          payload: setDefaults(
            {
              first_name,
              last_name,
              cvv,
              brand,
              card_number: rawNumber,
              expiration_month: Number(expiration_month),
              expiration_year: Number('20' + expiration_year),
              billing_address_street: address.street,
              billing_address_apt_suite: address.apt_suite,
              billing_address_city: address.city,
              billing_address_state: address.state,
              billing_address_zip: address.zip,
            },
            ''
          ) as JSONValue,
        },
      })
      .then(() => {
        dispatch(actions.profile.setCcDetailsPersisted());
      })
      .catch(() => {
        dispatch(
          actions.setToast({
            text: (
              <Text.P className={`${banner ? 'pt-4' : null}`}>
                We failed to authorize this card. Please double check the
                information you entered, and correct any invalid information, or
                try again later if you are confident the information entered is
                correct.
              </Text.P>
            ),
            variant: 'warning',
            onClose: () => dispatch(actions.setToast(null)),
          })
        );
        dispatch(actions.profile.setCcDetailsPersistence('error'));
        return Promise.reject();
      });
  };

  const errorState = makeErrorState(!!invalid, validation);
  return (
    <EditSection
      validationErrors={validationErrors}
      invalid={!!invalid}
      onSave={onSave}
      onCancel={() => dispatch(actions.profile.setCcEditMode('read'))}
      label="Payment method"
      saving={persistence === 'saving'}
    >
      <Text.P>Enter the details for your new card</Text.P>
      <div className="flex flex-wrap md:gap-x-2 gap-y-4">
        <div className="basis-full md:basis-[calc(50%-4px)] space-y-1">
          <Text.P>First name</Text.P>
          <TextInput
            value={first_name}
            state={errorState('first_name')}
            onChange={(v) =>
              dispatch(actions.profile.setEditedCcDetails({ first_name: v }))
            }
          />
        </div>
        <div className="basis-full md:basis-[calc(50%-4px)] space-y-1">
          <Text.P>Last name</Text.P>
          <TextInput
            value={last_name}
            state={errorState('last_name')}
            onChange={(v) =>
              dispatch(actions.profile.setEditedCcDetails({ last_name: v }))
            }
          />
        </div>
        <div className="basis-full space-y-1">
          <Text.P>Credit card number</Text.P>
          <CreditCardInput
            value={number}
            setValidation={setCardValidation}
            state={errorState('number')}
            onChange={(v) =>
              dispatch(actions.profile.setEditedCcDetails({ number: v }))
            }
          />
        </div>
        <div className="basis-full space-y-1 md:basis-[calc(66%-4px)]">
          <Text.P>Expiration Date</Text.P>
          <DateInput.Expiration
            value={expiration_date}
            state={errorState('expiration_date')}
            onChange={(v) => {
              dispatch(
                actions.profile.setEditedCcDetails({
                  expiration_date: v,
                })
              );
            }}
          />
        </div>
        <div className="basis-full space-y-1 md:basis-[calc(33%-4px)]">
          <Text.P>CVV</Text.P>
          <TextInput
            value={cvv}
            state={errorState('cvv')}
            onChange={changeOnlyIfValid([
              match(
                new RegExp(`^\\d{0,${cardValidation?.card?.code?.size || 3}}$`)
              ),
            ])((v) => dispatch(actions.profile.setEditedCcDetails({ cvv: v })))}
          />
        </div>
        <Text.P.Bold className="basis-full pt-2">Billing address</Text.P.Bold>
        {homeAddress && !!homeAddress.street && (
          <Checkbox
            checked={homeAddress && !!homeAddress.street && useHomeForBilling}
            label="Same as home address"
            onCheck={() => setUseHomeForBilling(true)}
            onUncheck={() => setUseHomeForBilling(false)}
          />
        )}
        {!useHomeForBilling ? (
          <>
            <div className="basis-full space-y-1 md:basis-[calc(66%-4px)]">
              <Text.P>Street address</Text.P>
              <TextInput
                state={errorState('street')}
                value={billing_address.street}
                onChange={(v) =>
                  dispatch(
                    actions.profile.setEditedBillingAddress({ street: v })
                  )
                }
              />
            </div>
            <div className="basis-full space-y-1 md:basis-[calc(33%-4px)]">
              <Text.P>Apt #</Text.P>
              <TextInput
                value={billing_address.apt_suite}
                onChange={(v) =>
                  dispatch(
                    actions.profile.setEditedBillingAddress({
                      apt_suite: v,
                    })
                  )
                }
              />
            </div>
            <div className="basis-full space-y-1 md:basis-[calc(66%-4px)]">
              <Text.P>City</Text.P>
              <TextInput
                value={billing_address.city}
                state={errorState('city')}
                onChange={(v) =>
                  dispatch(actions.profile.setEditedBillingAddress({ city: v }))
                }
              />
            </div>
            <div className="basis-full space-y-1 md:basis-[calc(33%-4px)]">
              <Text.P>State</Text.P>
              {/* height of this dropdown needs to subtract the Text.P height from the parent's height */}
              <div className="h-[calc(100%-24px)]">
                <Dropdown
                  value={billing_address.state}
                  state={errorState('state')}
                  onChange={(v) =>
                    dispatch(
                      actions.profile.setEditedBillingAddress({ state: v })
                    )
                  }
                  options={usStateAbbreviations.map((v) => ({
                    key: v,
                    value: v,
                  }))}
                />
              </div>
            </div>
            <div className="basis-full space-y-1">
              <Text.P>Zip code</Text.P>
              <TextInput
                value={billing_address.zip}
                state={errorState('zip')}
                onChange={(v) =>
                  dispatch(actions.profile.setEditedBillingAddress({ zip: v }))
                }
              />
            </div>
          </>
        ) : null}
      </div>
    </EditSection>
  );
};

const CreditCardDetails = ({
  creditCard,
  patientData,
  homeAddress,
  setEditing,
}: CreditCardProps) => {
  return isEditing(creditCard) ? (
    <Edit {...creditCard} patientData={patientData} homeAddress={homeAddress} />
  ) : (
    <Read {...creditCard} edit={() => setEditing(true)} />
  );
};

export default CreditCardDetails;
