// See https://www.figma.com/file/Crdh2OXWX2ugiaE7Ghchha/MVP-Design-System?node-id=4%3A227
import { Component } from 'react';
import tw from 'tailwind-styled-components';

// "Bold" in the Design System means weight 500
const Bold = (c: Component) => tw(c)`font-[500]`;

const Light = (c: Component) => tw(c)`font-[400]`;

const Underline = (c: Component) => tw(c)`underline`;

const Serif = {
  H1: tw.h1`font-serif text-[40px] leading-[50px] font-[700]`,
  H2: tw.h2`font-serif text-[32px] leading-[40px] font-[700]`,
  H3: tw.h3`font-serif text-[24px] leading-[30px] font-[700]`,
};

const H1 = tw.h1`text-[20px] font-[400] leading-[28px] md:text-[30px] md:leading-[36px] md:font-[500]`;
const H2 = tw.h2`text-[24px] leading-[32px] font-[500]`;
const H3 = tw.h3`text-[20px] leading-[28px] font-[500]`;
const H4 = tw.h4`text-[18px] leading-[28px] font-[500]`;
H4.Bold = Bold(H4);

Serif.H1.Light = Light(Serif.H1);

const P = tw.p`text-[16px] leading-[24px]`;
P.Bold = Bold(P);
P.Inline = tw.span`text-[16px] leading-[24px]`;
P.Inline.Bold = Bold(P.Inline);
P.Inline.Bold.Underline = Underline(Bold(P.Inline));

const Small = tw.span`text-[14px] leading-[20px] font-[400]`;
Small.Bold = Bold(Small);
Small.Bold.Underline = Underline(Bold(Small));

const SmallBlock = tw.p`text-[14px] leading-[20px] font-[400]`;
SmallBlock.Bold = Bold(SmallBlock);
Small.Block = SmallBlock;

const XSmall = tw.span`text-[12px] leading-[16px] font-[400]`;
XSmall.Bold = Bold(XSmall);

export default {
  H1,
  H2,
  H3,
  H4,
  Serif,
  P,
  Small,
  XSmall,
};
