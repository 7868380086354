import { Dispatch, useContext, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { StateContext } from '@/App';
import {
  ActiveRelationshipAccount,
  AppointmentV2,
  AuthenticatedState,
  Data,
  isLoaded,
  Loaded,
  ReactChildren,
  ServerData,
  Statement,
  StatementRecord,
} from '@/utils/types';
import actions, { Action } from './actions';
import fetch from '@/utils/fetch';
import ErrorPage from '@/pages/ErrorPage';
import Loader from '@/components/Loader';
import getCookies from '@/utils/cookies';
import { getAllThreads } from '@/utils/api';
import { getPatientUuidFromSelectedAccount } from '@/utils/session';

// describes how to load data and what dependencies are required before load
type Loader = {
  load: (
    dispatch: Dispatch<Action>,
    data: AuthenticatedState['data'],
    flags: LDFlagSet
  ) => Promise<unknown>;
  dependencies: (keyof Data)[] | never[];
};

const loadProfile: Loader = {
  load: (dispatch: Dispatch<Action>) => {
    const selectedAccountCookie = getCookies()['__Host-selectedAccount'];
    const selectedAccount: ActiveRelationshipAccount = JSON.parse(
      selectedAccountCookie
    );
    const { patient_uuid } = selectedAccount;
    dispatch(
      actions.async.setLoading({
        key: 'profile',
        loadingState: 'in_progress',
      })
    );
    return fetch
      .json(
        '/api/patient_details?' +
          new URLSearchParams({
            patient_uuid,
          }),
        {
          method: 'GET',
        }
      )
      .then(({ ...details }) =>
        dispatch(
          actions.profile.setProfile({
            details,
            ...selectedAccount,
          })
        )
      )
      .catch(() => {
        dispatch(
          actions.async.setLoading({ key: 'profile', loadingState: 'error' })
        );
      });
  },
  dependencies: [],
};

const loadPaymentInfo: Loader = {
  load: (dispatch: Dispatch<Action>) => {
    dispatch(
      actions.async.setLoading({
        key: 'creditCard',
        loadingState: 'in_progress',
      })
    );
    dispatch(
      actions.async.setLoading({
        key: 'insurance',
        loadingState: 'in_progress',
      })
    );
    return fetch
      .json(
        '/api/payment_details?' +
          new URLSearchParams({
            patient_uuid: getPatientUuidFromSelectedAccount(),
          }),
        {
          method: 'GET',
        }
      )
      .then(({ insurance, credit_card, payment_method }) =>
        dispatch(
          actions.profile.setPaymentInfo({
            insurance: {
              ...insurance,
              payment_method: payment_method,
            },
            creditCard: {
              ...credit_card,
              billingAddress: {},
            },
          })
        )
      )
      .catch(() => {
        dispatch(
          actions.async.setLoading({
            key: 'creditCard',
            loadingState: 'error',
          })
        );
        dispatch(
          actions.async.setLoading({
            key: 'insurance',
            loadingState: 'error',
          })
        );
      });
  },
  dependencies: [],
};

const loadAcceptedInsurances: Loader = {
  load: (dispatch: Dispatch<Action>) => {
    dispatch(
      actions.async.setLoading({
        key: 'acceptedInsurances',
        loadingState: 'in_progress',
      })
    );
    return fetch
      .json('/api/accepted_insurances', { method: 'GET' })
      .then((r) => {
        dispatch(actions.setAcceptedInsurances(r));
      })
      .catch(() =>
        dispatch(
          actions.async.setLoading({
            key: 'acceptedInsurances',
            loadingState: 'error',
          })
        )
      );
  },
  dependencies: [],
};
const loadCarriers: Loader = {
  load: (dispatch: Dispatch<Action>, data: AuthenticatedState['data']) => {
    if (!isLoaded(data.profile)) {
      return Promise.reject();
    }

    dispatch(
      actions.async.setLoading({
        key: 'carriers',
        loadingState: 'in_progress',
      })
    );

    const urlParams = new URLSearchParams({
      state: data.profile.location,
    });

    const url = `/api/insurance_for_state?${urlParams.toString()}`;
    return fetch
      .json(url, { method: 'GET' })
      .then((r) => {
        dispatch(actions.profile.setCarriers(r));
      })
      .catch(() =>
        dispatch(
          actions.async.setLoading({
            key: 'carriers',
            loadingState: 'error',
          })
        )
      );
  },
  dependencies: ['profile'],
};

const loadSelfPayRates: Loader = {
  load: (dispatch: Dispatch<Action>) => {
    dispatch(
      actions.async.setLoading({
        key: 'selfPayRates',
        loadingState: 'in_progress',
      })
    );
    return fetch
      .json('/api/self_pay_rates', { method: 'GET' })
      .then((r) => {
        dispatch(actions.setSelfPayRates(r));
      })
      .catch(() =>
        dispatch(
          actions.async.setLoading({
            key: 'selfPayRates',
            loadingState: 'error',
          })
        )
      );
  },
  dependencies: [],
};

const loadProviderSpecializations: Loader = {
  load: (dispatch: Dispatch<Action>) => {
    dispatch(
      actions.async.setLoading({
        key: 'providerSpecializations',
        loadingState: 'in_progress',
      })
    );
    return fetch
      .json('/api/provider_specializations', { method: 'GET' })
      .then((r) => {
        dispatch(actions.setProviderSpecializations(r));
      })
      .catch(() =>
        dispatch(
          actions.async.setLoading({
            key: 'providerSpecializations',
            loadingState: 'error',
          })
        )
      );
  },
  dependencies: [],
};

const loadProviderModalities: Loader = {
  load: (dispatch: Dispatch<Action>) => {
    dispatch(
      actions.async.setLoading({
        key: 'providerModalities',
        loadingState: 'in_progress',
      })
    );
    return fetch
      .json('/api/provider_modalities', { method: 'GET' })
      .then((r) => {
        dispatch(actions.setProviderModalities(r));
      })
      .catch(() =>
        dispatch(
          actions.async.setLoading({
            key: 'providerModalities',
            loadingState: 'error',
          })
        )
      );
  },
  dependencies: [],
};

const loadAdvancedMdOfficeKeyStateMap: Loader = {
  load: (dispatch: Dispatch<Action>) => {
    dispatch(
      actions.async.setLoading({
        key: 'advancedMdOfficeKeyStateMap',
        loadingState: 'in_progress',
      })
    );
    return fetch
      .json('/api/advanced_md_office_key_state_map', { method: 'GET' })
      .then((r) => {
        dispatch(actions.setAdvancedMdOfficeKeyStateMap(r));
      })
      .catch(() =>
        dispatch(
          actions.async.setLoading({
            key: 'advancedMdOfficeKeyStateMap',
            loadingState: 'error',
          })
        )
      );
  },
  dependencies: [],
};

const loadProviderOptions: Loader = {
  load: async (dispatch: Dispatch<Action>, data) => {
    if (!isLoaded(data.patientData)) {
      return Promise.reject();
    }

    const actionKey = 'providerOptions';
    dispatch(
      actions.async.setLoading({
        key: actionKey,
        loadingState: 'in_progress',
      })
    );

    const request = fetch.json(
      `/api/provider_options?${new URLSearchParams({
        advanced_md_office_key:
          data.patientData.advanced_md_office_key.toString(),
      })}`,
      { method: 'GET' }
    );
    let response;
    try {
      response = await request;
    } catch (e) {
      dispatch(
        actions.async.setLoading({
          key: 'providerOptions',
          loadingState: 'error',
        })
      );
      return;
    }
    dispatch(actions.setProviderOptions(response));
  },
  dependencies: ['patientData'],
};

const loadBilling: Loader = {
  load: (dispatch: Dispatch<Action>, data) => {
    if (!isLoaded(data.patientData)) {
      return Promise.reject();
    }

    const { patient_record_uuid } = data.patientData;
    dispatch(
      actions.async.setLoading({
        key: 'billing',
        loadingState: 'in_progress',
      })
    );

    return fetch
      .json(
        '/api/billing_history?' +
          new URLSearchParams({
            patient_record_uuid,
          }),
        {
          method: 'GET',
        }
      )
      .then((payload) => {
        const statements = payload.statements.map(
          (statement: StatementRecord): Statement => ({
            statement,
            link:
              '/links/download_billing_pdf?' +
              new URLSearchParams({
                date: statement.date_of_service,
                patient_record_uuid,
              }),
          })
        );

        dispatch(
          actions.billing.setBilling({
            statements,
            balance: payload.total_patient_balance,
            payments: payload.payments,
          })
        );
      })
      .catch(() =>
        dispatch(
          actions.async.setLoading({
            key: 'billing',
            loadingState: 'error',
          })
        )
      );
  },
  dependencies: ['patientData'],
};

const loadMessageThreads: Loader = {
  load: async (dispatch: Dispatch<Action>) => {
    try {
      const threads = await getAllThreads(getPatientUuidFromSelectedAccount());
      dispatch(actions.messaging.setMessageThreads(threads));
    } catch (e) {
      console.error('Error fetching message threads from api', e);
      dispatch(actions.messaging.setMessageThreads([]));
    }
  },
  dependencies: [],
};

const loadCareTeam: Loader = {
  load: (dispatch: Dispatch<Action>) => {
    return fetch
      .json(
        '/api/care-team?' +
          new URLSearchParams({
            patient_uuid: getPatientUuidFromSelectedAccount(),
          }),
        {
          method: 'GET',
        }
      )
      .then((r) => {
        dispatch(actions.careTeam_v2.setCareTeam(r['payload']));
      })
      .catch(() => {
        dispatch(
          actions.async.setLoading({
            key: 'careTeam_v2',
            loadingState: 'error',
          })
        );
      });
  },
  dependencies: [],
};

const loadCareTeamV3: Loader = {
  load: (dispatch: Dispatch<Action>) => {
    return fetch
      .json(
        '/api/care-team-v3?' +
          new URLSearchParams({
            patient_uuid: getPatientUuidFromSelectedAccount(),
          }),
        {
          method: 'GET',
        }
      )
      .then((r) => {
        dispatch(actions.careTeam_v3.setCareTeam(r['payload']));
      })
      .catch(() => {
        dispatch(
          actions.async.setLoading({
            key: 'careTeam_v3',
            loadingState: 'error',
          })
        );
      });
  },
  dependencies: ['patientData'],
};

const loadAppointmentsV2: Loader = {
  load: (dispatch: Dispatch<Action>, data) => {
    if (!isLoaded(data.patientData)) {
      return Promise.reject();
    }
    const { patient_record_uuid } = data.patientData;
    return fetch
      .json(
        '/api/appointments_v2?' +
          new URLSearchParams({
            patient_record_uuid,
          }),
        {
          method: 'GET',
        }
      )
      .then((r) => {
        const appointments: Array<
          AppointmentV2 & {
            start_time_iso8601: string;
            end_time_iso8601: string;
          }
        > = r['payload']['appointments'];
        appointments.forEach((appt) => {
          appt['start_time'] = appt['start_time_iso8601'];
          appt['end_time'] = appt['end_time_iso8601'];
        });
        dispatch(actions.appointments_v2.setAppointments(appointments));
      })
      .catch(() => {
        dispatch(
          actions.async.setLoading({
            key: 'appointments_v2',
            loadingState: 'error',
          })
        );
      });
  },
  dependencies: ['patientData'],
};

const loadPatientEvents: Loader = {
  load: (dispatch: Dispatch<Action>, data) => {
    if (!isLoaded(data.patientData)) {
      return Promise.reject();
    }
    const { patient_record_uuid } = data.patientData;
    dispatch(
      actions.async.setLoading({
        key: 'patientEvents',
        loadingState: 'in_progress',
      })
    );
    return fetch
      .json(
        '/api/patient_events?' +
          new URLSearchParams({
            patient_record_uuid,
          }),
        {
          method: 'GET',
        }
      )
      .then((r) =>
        dispatch(actions.patientEvents.setPatientEvents(r['payload']['rows']))
      )
      .catch(() => {
        dispatch(
          actions.async.setLoading({
            key: 'patientEvents',
            loadingState: 'error',
          })
        );
      });
  },
  dependencies: ['patientData'],
};

const loadSurveys: Loader = {
  load: (dispatch: Dispatch<Action>, data) => {
    if (!isLoaded(data.appointments_v2)) {
      return Promise.reject();
    }

    const { appointments_v2 } = data;

    // I believe this will be loaded as it is a dependency for this loader
    const appointment_uuids = appointments_v2.rows.map(
      ({ ref }) => ref.split('/')[2]
    );

    dispatch(
      actions.async.setLoading({
        key: 'surveys',
        loadingState: 'in_progress',
      })
    );
    return fetch
      .json('/api/surveys', {
        method: 'POST',
        body: {
          patient_uuid: getPatientUuidFromSelectedAccount(),
          appointment_uuids,
        },
      })
      .then((r) => {
        dispatch(actions.surveys.setSurveys(r.payload));
      })
      .catch(() => {
        dispatch(actions.surveys.setSurveys([]));
        dispatch(
          actions.async.setLoading({
            key: 'surveys',
            loadingState: 'error',
          })
        );
      });
  },
  dependencies: ['appointments_v2'],
};

const loadData: Record<keyof Data, Loader> = {
  // patient data
  profile: loadProfile,
  patientData: loadProfile,
  // payment info
  creditCard: loadPaymentInfo,
  insurance: loadPaymentInfo,
  billing: loadBilling,
  appointments_v2: loadAppointmentsV2,
  patientEvents: loadPatientEvents,
  messaging: loadMessageThreads,
  // generic data
  acceptedInsurances: loadAcceptedInsurances,
  advancedMdOfficeKeyStateMap: loadAdvancedMdOfficeKeyStateMap,
  carriers: loadCarriers,
  providerOptions: loadProviderOptions,
  providerSpecializations: loadProviderSpecializations,
  providerModalities: loadProviderModalities,
  selfPayRates: loadSelfPayRates,
  careTeam_v2: loadCareTeam,
  careTeam_v3: loadCareTeamV3,
  surveys: loadSurveys,
};

// mutex to prevent concurrent requests for the same data
class Mutex {
  loading: Set<Loader>;
  constructor() {
    this.loading = new Set();
  }

  load =
    (loader: Loader) =>
    (
      dispatch: Dispatch<Action>,
      data: AuthenticatedState['data'],
      flags: LDFlagSet
    ) => {
      if (!this.loading.has(loader)) {
        this.loading.add(loader);
        loader.load(dispatch, data, flags).finally(() => {
          this.loading.delete(loader);
        });
      }
    };
}

const mutex = new Mutex();

// This should only be rendered once at the top-level.
// This hook sets up useEffect hooks for all keys in state.data
// to load the data that has a loadingState of `needed` after
// loading all of its dependencies.
export const useLoadData = () => {
  const {
    state,
    dispatch,
  }: { state: AuthenticatedState; dispatch: Dispatch<Action> } =
    useContext(StateContext);

  const dataEntry = (k: keyof Data) => state.data[k];
  const flags = useFlags();

  Object.entries(state.data).forEach((e) => {
    const [k, data] = e;
    const key = k as keyof Data;
    const loader = loadData[key];

    // these useEffect hooks listen to changes in loadingState for each data key and its dependencies,
    // sets loadingState to `needed` for any dependencies that are needed,
    // and loads the data once all dependencies are met
    // Note: when using a feature flag to determine how loaders fetch data, when toggling the Feature Flag off and on in
    // Launch Darkly, a refresh may be required to fetch data properly
    useEffect(() => {
      if (data.loadingState === 'needed') {
        if (
          loader.dependencies.find(
            (dep) => dataEntry(dep).loadingState === 'error'
          )
        ) {
          // set loading state to 'error' if any dependencies errored and return early
          dispatch(actions.async.setLoading({ key, loadingState: 'error' }));
          return;
        }

        const dependenciesToLoad: (keyof Data)[] = [];

        loader.dependencies.forEach((dep) => {
          if (state.data[dep].loadingState === 'pending') {
            dependenciesToLoad.push(dep);
          }
        });

        dependenciesToLoad.forEach((dep) => {
          // mark any pending dependencies as needed
          dispatch(
            actions.async.setLoading({ key: dep, loadingState: 'needed' })
          );
        });

        const dependenciesLoading = loader.dependencies.filter((dep) => {
          dataEntry(dep).loadingState === 'in_progress';
        });
        if (dependenciesToLoad.length || dependenciesLoading.length) {
          // don't load until dependencies are fully loaded
          return;
        }

        // if all dependencies are loaded successfully, load this data
        mutex.load(loader)(dispatch, state.data, flags);
      }
    }, [
      ...loader.dependencies.map((dep) => dataEntry(dep).loadingState),
      data.loadingState,
      flags,
    ]);
  });
};

type RelevantData<K extends keyof Data> = {
  [key in K]: ServerData<Data[key]>;
};

type LoadedRelevantData<K extends keyof Data> = {
  [key in K]: Loaded<Data[key]>;
};

function WithData<K extends keyof Data>({
  data,
  children,
  renderError = () => <ErrorPage />,
  renderLoading = () => <Loader.Contained />,
}: {
  data: RelevantData<K>;
  renderError?: () => ReactChildren;
  renderLoading?: () => ReactChildren;
  children: (data: LoadedRelevantData<K>) => ReactChildren;
}) {
  const values = Object.values<ServerData<unknown>>(data);

  if (values.every((v) => v.loadingState === 'done')) {
    return children(data as LoadedRelevantData<K>);
  } else if (values.find((v) => v.loadingState === 'error')) {
    return renderError();
  } else {
    return renderLoading();
  }
}

// hook that allows you to specify keys of data you care about, with which it:
// 1. loads that data
// 2. builds a WithData component that handles the logic of loading and error states
// 3. returns the data you want so you can use it outside of the render prop to WithData
function useData<K extends keyof Data>(toLoad: K[]) {
  const { state, dispatch } = useContext(StateContext);

  // data specified in toLoad that comes from state.data
  const relevantData = {} as {
    [key in Extract<K, keyof Data>]: typeof state.data[key];
  };

  toLoad.forEach((key) => {
    // aggregate data keys from the keys passed in
    const keys: (keyof Data)[] = [];
    Object.assign(relevantData, { [key]: state.data[key as keyof Data] });
    keys.push(key);

    // set up a useEffect to load the data needed, if it isn't loaded
    // TODO: if we need to "hard reload" some data in some contexts, pass options to do that through to here
    keys.forEach((dataKey) => {
      useEffect(() => {
        if (state.data[dataKey].loadingState !== 'done') {
          dispatch(
            actions.async.setLoading({ key: dataKey, loadingState: 'needed' })
          );
        }
      }, []);
    });
  });

  const allRelevantData = {
    ...relevantData,
  };

  // component that takes care of handling loading and error states and passes loaded data to render prop

  return {
    data: allRelevantData,
    WithData,
  };
}

export default useData;
