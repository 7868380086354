import fetch from './fetch';
import { AnalyticsEvent } from './types';

const trackEvent = ({
  patient_record_uuid,
  patient_uuid,
  salesforce_contact_id,
  ...rest
}: AnalyticsEvent) => {
  void fetch.json('/api/track', {
    method: 'POST',
    body: {
      patientIdentifiers: {
        patient_record_uuid,
        ...(patient_uuid && { patient_uuid }),
        ...(salesforce_contact_id && { salesforce_contact_id }),
      },
      ...rest,
    },
  });
};

export default trackEvent;
