import Text from '@/components/Text';
import { Link } from 'react-router-dom';
import AppointmentPicker from '@/pages/care-team/AppointmentPicker';
import ProviderHeadshot from '@/pages/care-team/ProviderHeadshot';
import { providerLicenses } from '@/utils/constants';
import FullWidthHeader from '../FullWidthHeader';
import Clock from '../icons/Clock';
import Video from '../icons/Video';
import ContactProviderFooter from './ContactProviderFooter';
import OldAppointmentCard from './OldAppointmentCard';
import {
  ActiveProviderShapeV3,
  InactiveProviderShape,
  OldAppointmentProps,
  SeriesType,
  TherapyType,
} from '@/utils/types';

type ChooseAppointmentProps = {
  provider: ActiveProviderShapeV3 | InactiveProviderShape;
  hasBookableAppointments: boolean;
  therapyType: TherapyType;
  seriesType: SeriesType;
  appointmentLengthInMinutes: number;
  oldAppointmentProps?: OldAppointmentProps;
  isHold: boolean;
};

const ChooseAppointment = ({
  provider,
  hasBookableAppointments,
  therapyType,
  seriesType,
  appointmentLengthInMinutes,
  oldAppointmentProps,
  isHold,
}: ChooseAppointmentProps) => {
  return (
    <div className="space-y-10">
      {/* Header/navigation area */}
      <FullWidthHeader>Choose a new appointment</FullWidthHeader>

      {/* Body */}
      <div className="flex flex-wrap lg:flex-nowrap w-full lg:space-x-8">
        {/* Select new appointment */}
        <div className="border-1 border-tertiary-2 rounded-2 flex-auto px-6 py-8 space-y-4 w-full lg:max-w-[calc(100%-338px)]">
          {/* Provider header */}
          <div className="flex relative justify-start items-center gap-x-6">
            <ProviderHeadshot
              profileImage={provider.profile_image_s3_url}
              className="block w-20 h-20 self-start mb-4"
            />
            <div className="w-full">
              <div className="font-semibold mt-0 mb-3 text-xl text-left">
                <Text.H3 className="text-tertiary-7 mb:2">
                  {provider.first_name} {provider.last_name}
                </Text.H3>
                <Text.Small className="text-tertiary-5 pb-4">
                  {providerLicenses[provider.licenses[0]]?.name || ''}
                </Text.Small>
                {/* TODO: handle multiple licenses */}
              </div>
            </div>
          </div>

          {hasBookableAppointments ? (
            <>
              <div className="border-b border-t border-tertiary-2">
                <AppointmentPicker
                  generateLinkProps={(newAppointment) => ({
                    to: `${newAppointment}`,
                  })}
                  provider={provider}
                  therapyType={therapyType}
                  seriesType={seriesType}
                  isHold={isHold}
                />
              </div>
              <div className="flex items-left space-x-5 text-tertiary-7">
                <div className="flex items-center space-x-2">
                  <Clock />
                  <Text.P>{appointmentLengthInMinutes} minutes</Text.P>
                </div>
                <div className="flex items-center space-x-2">
                  <Video />
                  <Text.P>Video session</Text.P>
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-col items-start justify-center border-t border-tertiary-2 pt-8 md:items-center">
              <Text.P.Bold className="mb-2">
                No upcoming availability
              </Text.P.Bold>
              <Text.P>
                <Link
                  to={`/messages/${provider.uuid}`}
                  className="cursor-pointer no-underline text-primary-3"
                >
                  Contact your provider{' '}
                </Link>
                to arrange a new time that works for your schedule
              </Text.P>
            </div>
          )}
        </div>

        {/* Old appointment info */}
        {oldAppointmentProps && (
          <OldAppointmentCard
            startTime={oldAppointmentProps.startTime}
            isFirstAppointment={oldAppointmentProps.isFirstAppointment}
            therapyType={therapyType}
            appointmentType={oldAppointmentProps.appointmentType}
            previousLengthInMinutes={
              oldAppointmentProps.previousLengthInMinutes
            }
          />
        )}
      </div>

      {/* Footer */}
      {hasBookableAppointments && (
        <ContactProviderFooter providerUuid={provider.uuid} />
      )}
    </div>
  );
};

export default ChooseAppointment;
