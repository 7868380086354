export interface BannerProps {
  children?: JSX.IntrinsicElements['div']['children'];
}

const Banner = ({ children }: BannerProps) => {
  return (
    <div>
      <div className="bg-warning-0 h-[64px] w-screen fixed top-0 left-0 z-50 flex justify-center items-center">
        {children}
      </div>
      <div className="pt-16"></div>
    </div>
  );
};

export default Banner;
