import { TherapyType } from '@/utils/types';
import Button, { ButtonProps } from '../../components/Button';
import Replace from '../../components/icons/Replace';
import ReplaceProviderTriggerV2 from './ReplaceProviderTriggerV2';
import stopPropagation from '@/utils/stop-propagation';

const ReplaceProviderButtonV2 = ({
  buttonProps,
  providerNpi,
  therapyType,
}: {
  buttonProps?: Omit<ButtonProps, 'children'>;
  providerNpi: number;
  therapyType: TherapyType;
}) => {
  return (
    <ReplaceProviderTriggerV2
      providerNpi={providerNpi}
      therapyType={therapyType}
    >
      {({ onClick }) => (
        <Button
          {...buttonProps}
          onClick={stopPropagation((e) => {
            e.preventDefault();
            onClick();
          })}
          renderIcon={(props) => <Replace {...props} />}
        >
          Replace
        </Button>
      )}
    </ReplaceProviderTriggerV2>
  );
};

export default ReplaceProviderButtonV2;
