import Text from '@/components/Text';
import { Link } from 'react-router-dom';

const ContactProviderFooter = ({ providerUuid }: { providerUuid: string }) => {
  return (
    <div className="my-3 text-tertiary-5">
      <Text.P>
        Not able to find a time that fits with your schedule?{' '}
        <Link
          to={`/messages/${providerUuid}`}
          className="text-primary-3 hover:text-primary-4 cursor-pointer inline"
        >
          Contact your provider
        </Link>
      </Text.P>
    </div>
  );
};

export default ContactProviderFooter;
