import { Billing } from '../../utils/types';

export default {
  setBilling: (payload: Billing) =>
    ({ type: 'billing/setBilling', payload } as const),
  setBillingTooltipOpen: (payload: boolean) =>
    ({
      type: 'billing/setBillingTooltipOpen',
      payload,
    } as const),
  setCardStatus: (payload: string) =>
    ({
      type: 'billing/setCardStatus',
      payload,
    } as const),
};
