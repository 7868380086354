import { useContext, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { StateContext } from '@/App';
import useData from '@/state/use-data';
import {
  AnalyticsEvent,
  JSONValue,
  ProviderProfileParams,
  Trigger,
} from '@/utils/types';
import RedirectToAppointmentsOnError from '@/pages/appointments/RedirectToAppointmentsOnError';
import { getBookableSlotsForProvider } from '@/utils/appointments';
import trackEvent from '@/utils/track';
import { getSlotsInNextSevenDays } from '@/utils/dates';
import ChooseAppointment from '@/components/appointments/ChooseAppointment';

const ChooseAppointmentWithoutPrevious = () => {
  const { providerNpi, therapyType } = useParams<ProviderProfileParams>();

  const { state } = useContext(StateContext);
  const pageState = state.ui.page;

  const { WithData, data } = useData(['careTeam_v3', 'patientData']);

  if (pageState.path !== 'appointments') {
    return null;
  }

  return (
    <WithData data={data}>
      {({ careTeam_v3: careTeam, patientData }) => {
        const [searchParams] = useSearchParams();
        const isDeeplink = !!searchParams.get('deeplink');

        const { patient_record_uuid } = patientData;
        const npi = Number(providerNpi);
        const provider = careTeam.active.find((p) => {
          return p?.npi === npi;
        });
        if (!providerNpi || Number.isNaN(npi) || !provider) {
          console.error('provider npi not on active care team');
          return <RedirectToAppointmentsOnError />;
        }

        if (therapyType !== 'psychiatric') {
          console.error('unsupported care type');
          return <RedirectToAppointmentsOnError />;
        }

        const { first_name, last_name } = provider;

        const seriesType = 'followup';
        const bookableSlots = getBookableSlotsForProvider(
          state.ui.usedAvailabilitySlots,
          provider,
          therapyType,
          seriesType,
          false
        );

        const hasBookableAppointments =
          bookableSlots && bookableSlots.length > 0;

        // This is hardcoded for psych followups and will need to be updated if we extend this feature to therapy
        const appointmentLengthInMinutes = 30;

        const trackBookNewFollowupEvent = (
          name: string,
          trigger: Trigger,
          metadata: { [key: string]: JSONValue } = {}
        ) => {
          const event: AnalyticsEvent = {
            product_area: 'Appointments',
            name,
            trigger,
            patient_record_uuid: patient_record_uuid,
            metadata: {
              provider_name: `${first_name} ${last_name}`,
              provider_npi: providerNpi,
              treatment_type:
                therapyType === 'psychiatric' ? 'psychiatry' : 'therapy',
              ...metadata,
            },
          };
          trackEvent(event);
        };

        useEffect(() => {
          const sessionsWithin7Days = getSlotsInNextSevenDays(bookableSlots);
          trackBookNewFollowupEvent(
            'schedule_follow_up_page_view',
            'Page load',
            {
              patient_app_linked_from_email: isDeeplink,
              available_sessions_count: bookableSlots.length,
              available_sessions_within_7_days_count: sessionsWithin7Days,
            }
          );
        }, []);

        return (
          <ChooseAppointment
            provider={provider}
            hasBookableAppointments={hasBookableAppointments}
            therapyType={therapyType}
            seriesType={seriesType}
            isHold={false}
            appointmentLengthInMinutes={appointmentLengthInMinutes}
          />
        );
      }}
    </WithData>
  );
};

export default ChooseAppointmentWithoutPrevious;
