import { useMemo } from 'react';
import {
  ActiveProviderShapeV3,
  InactiveProviderShape,
  TherapyType,
  ProviderWithFirstAppointmentInfo,
} from '@/utils/types';
import ProviderCardV2, { ProviderCardV2Props } from './ProviderCardV2';
import Text from '@/components/Text';
import { therapyTypeToVisitType } from '@/utils/constants';

interface CareTeamTabProps {
  providers: (ActiveProviderShapeV3 | InactiveProviderShape)[];
  active: boolean;
}

const CareTeamTab = ({ providers, active }: CareTeamTabProps) => {
  const providersAndHeadings: {
    heading: TherapyType;
    providers: ProviderWithFirstAppointmentInfo[];
  }[] = [];
  const sortedProviders = useMemo(
    () =>
      [...providers].sort((a, b) =>
        a.first_name.localeCompare(b.first_name, undefined, {
          sensitivity: 'base',
        })
      ),
    [providers]
  );

  const getProvidersAndHeadings = (
    therapyEvent: { therapy_type: TherapyType; has_had_appointment?: boolean },
    provider: ActiveProviderShapeV3 | InactiveProviderShape
  ) => {
    const providerAndHeading = providersAndHeadings.find(
      (providerAndHeading) => {
        return providerAndHeading.heading === therapyEvent.therapy_type;
      }
    );

    // has_had_appointment will be undefined for inactives
    const providerWithFirstApptInfo: ProviderWithFirstAppointmentInfo = {
      ...provider,
      has_had_appointment: therapyEvent.has_had_appointment,
    };
    if (providerAndHeading) {
      providerAndHeading.providers.push(providerWithFirstApptInfo);
    } else {
      providersAndHeadings.push({
        heading: therapyEvent.therapy_type,
        providers: [providerWithFirstApptInfo],
      });
    }
  };

  sortedProviders.forEach((provider) => {
    const therapyEvent = !active
      ? (provider as InactiveProviderShape).deactivation_events
      : (provider as ActiveProviderShapeV3).active_therapy_types;

    therapyEvent.forEach((therapyEvent) => {
      const therapyData: {
        therapy_type: TherapyType;
        has_had_appointment?: boolean;
      } = {
        therapy_type: therapyEvent.therapy_type,
      };
      if ('has_had_appointment' in therapyEvent) {
        therapyData.has_had_appointment = therapyEvent.has_had_appointment;
      }
      getProvidersAndHeadings(therapyData, provider);
    });
  });

  return (
    <>
      <div className="space-y-4 basis-full w-full">
        {providersAndHeadings
          .sort((a, b) =>
            a.heading.localeCompare(b.heading, undefined, {
              sensitivity: 'base',
            })
          )
          .map(({ heading, providers }) => {
            return (
              <div key={heading}>
                <div className="bg-gray-100 mb-3 p-3 rounded-3">
                  <Text.P.Bold>{therapyTypeToVisitType[heading]}</Text.P.Bold>
                </div>
                <div>
                  {providers.map((provider) => {
                    const props: ProviderCardV2Props = {
                      provider,
                      active,
                      therapyType: heading,
                    };
                    return <ProviderCardV2 key={provider.npi} {...props} />;
                  })}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default CareTeamTab;
