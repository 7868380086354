import { useContext, useCallback, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { StateContext } from '@/App';
import EmptyStatePage from '../EmptyStatePage';
import Text from '@/components/Text';
import useData from '@/state/use-data';
import {
  appointmentIsWithin24Hours,
  groupAppointmentsByMonthAndYear,
} from '@/utils/dates';
import { AnalyticsEvent, AppointmentParticipant } from '@/utils/types';
import AppointmentCard from '@/components/AppointmentCard';
import { therapyTypeToVisitType } from '@/utils/constants';
import { ProviderDetail, Loaded, SelfPayRates } from '@/utils/types';
import useClipboard from '@/utils/use-clipboard';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getPatientFromParticipants } from '@/utils/appointments';
import { Link, useSearchParams } from 'react-router-dom';
import trackEvent from '@/utils/track';
import { getCancellationFee } from '@/utils/billing';

dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);

const EmptyStateMessage = () => (
  <Text.P className="text-tertiary-7 max-w-[580px]">
    Contact your care team to schedule your next appointment. You can also{' '}
    <Link
      to="/care-team?addCare=any"
      className="cursor-pointer no-underline text-primary-3"
    >
      expand your care team
    </Link>
    .
  </Text.P>
);

const EmptyState = () => (
  <div>
    <Text.H1>Appointments</Text.H1>
    <div className="mt-32">
      <EmptyStatePage
        header="No upcoming appointments"
        message={<EmptyStateMessage />}
      />
    </div>
  </div>
);
const Appointments = () => {
  const { state } = useContext(StateContext);
  const { WithData, data } = useData([
    'selfPayRates',
    'careTeam_v3',
    'appointments_v2',
    'patientData',
    'surveys',
  ]);
  const [writeToClipboard] = useClipboard();
  const [searchParams] = useSearchParams();

  const page = state.ui.page;

  if (page.path !== 'appointments') {
    return null;
  }

  return (
    <WithData data={data}>
      {({
        careTeam_v3,
        selfPayRates,
        appointments_v2,
        patientData,
        surveys,
      }) => {
        const { patient_record_uuid } = patientData;
        // filter out past appointments, then grp into months and year
        const futureAppointments = appointments_v2.rows
          .filter(({ end_time }) => {
            if (!end_time) return false;

            const now = dayjs();
            const endTime = dayjs(end_time);

            return endTime.isSameOrAfter(now);
          })
          .sort(
            (a, b) => dayjs(a.start_time).unix() - dayjs(b.start_time).unix()
          );
        const futureAppointmentsByMonthAndYear =
          groupAppointmentsByMonthAndYear(futureAppointments);
        const monthYears = Object.keys(futureAppointmentsByMonthAndYear);

        useEffect(() => {
          const num_appointments_v2 = appointments_v2.rows.length;
          const now = dayjs();
          const last_appointment =
            num_appointments_v2 > 0
              ? appointments_v2.rows[num_appointments_v2 - 1]
              : null;

          const eventBody: AnalyticsEvent = {
            product_area: 'Appointments',
            name: 'page_view',
            trigger: 'Page load',
            patient_record_uuid,
            metadata: {
              patient_app_linked_from_email: !!searchParams.get('deeplink'),
              num_appointments_v2: num_appointments_v2,
              num_future_appointments: futureAppointments.length,
              user_local_time: now.format(),
              last_appointment_end_time: last_appointment
                ? last_appointment.end_time
                : null,
              appointment_parsing_error_old: dayjs(
                '2024-01-01 00:00:00+00'
              ).isValid(),
              appointments_missing_providers: !futureAppointments.every(
                (appt) => careTeam_v3.active.find((p) => p.npi === appt.npi)
              ),
            },
          };
          trackEvent(eventBody);
        }, []);

        const getFeeCallback = useCallback(
          (
            provider: ProviderDetail,
            selfPayRates: Loaded<{ data: SelfPayRates }>
          ) => {
            return getCancellationFee(provider, selfPayRates);
          },
          []
        );

        const copyToClipboard = (joinUrl: string) => {
          writeToClipboard(joinUrl, 'Success! Your link');
        };

        return monthYears.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="text-tertiary-7">
            <Text.H1 className="mb-3">Appointments</Text.H1>
            {monthYears.map((monthYear) => {
              const appointmentsInMonthAndYear =
                futureAppointmentsByMonthAndYear[monthYear];
              const date = dayjs(monthYear, 'MMMM YYYY');
              const month = date.format('MMMM');
              return (
                <div key={monthYear}>
                  <Text.H3 className="my-6">{month}</Text.H3>
                  {appointmentsInMonthAndYear.map((appointment, idx) => {
                    const {
                      ref,
                      join_url,
                      status,
                      series_type,
                      therapy_type,
                      provider_ref,
                      participants,
                      start_time,
                      end_time,
                    } = appointment;
                    const primaryParticipant =
                      getPatientFromParticipants(participants);
                    const appointmentUUID = ref.split('/')[2];
                    const provider = careTeam_v3.active.find(
                      (p) => p.npi === appointment.npi
                    );

                    const appointmentInNextDay =
                      appointmentIsWithin24Hours(start_time);

                    if (!provider) {
                      Sentry.captureMessage(
                        `could not locate provider on appointments page with appointment npi: ${appointment.npi}`
                      );
                      return null;
                    }

                    const {
                      approved_client_email,
                      approved_client_facing_email,
                      first_name,
                      last_name,
                      npi,
                    } = provider;
                    const surveyForAppointment = surveys.data.find(
                      ({ appointment_uuid }) =>
                        appointment_uuid === appointmentUUID
                    );
                    const checkedIn = !!(
                      surveyForAppointment && surveyForAppointment.completed_at
                    );
                    const providerName = `${first_name} ${last_name}`;
                    const cancellationFee = getFeeCallback(
                      provider,
                      selfPayRates
                    );

                    const cardMargin =
                      idx === appointmentsInMonthAndYear.length - 1
                        ? ''
                        : 'mb-3';
                    return (
                      <div key={ref} className={cardMargin}>
                        <AppointmentCard
                          providerName={providerName}
                          isFirstAppointment={series_type === 'initial'}
                          appointmentType={
                            therapy_type && therapyTypeToVisitType[therapy_type]
                          }
                          handleCopyZoomLink={copyToClipboard}
                          joinUrl={join_url}
                          isHold={status === 'held'}
                          providerEmail={
                            approved_client_facing_email
                              ? approved_client_email
                              : undefined
                          }
                          providerNpi={npi}
                          cancellationFee={
                            appointmentInNextDay ? cancellationFee : undefined
                          }
                          patientRecordUUID={patient_record_uuid}
                          appointmentUUID={appointmentUUID}
                          patientRef={
                            (primaryParticipant as AppointmentParticipant).ref
                          }
                          providerRef={provider_ref}
                          startTime={start_time}
                          endTime={end_time}
                          seriesType={series_type}
                          therapyType={therapy_type}
                          checkedIn={checkedIn}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      }}
    </WithData>
  );
};

export default Appointments;
