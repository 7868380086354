import { useState } from 'react';
import Button from '@/components/Button';
import Ellipsis from '@/components/icons/Ellipsis';
import ReplaceProviderButtonV2 from './ReplaceProviderButtonV2';
import { ButtonProps } from '@/components/Button';
import { TherapyType } from '@/utils/types';

interface Props {
  npi: number;
  // relatively confident V2 care team will always have this, can remove once V1 is gone
  therapyType: TherapyType;
}

const MobileProviderCardDropdown = ({ npi, therapyType }: Props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const buttonProps: Omit<ButtonProps, 'children'> = {
    size: 'small',
    variant: 'tertiary-no-border',
    align: 'left',
  };
  return (
    <div
      className="relative md:hidden"
      onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
    >
      <Button size="small">
        <Ellipsis />
      </Button>
      {mobileMenuOpen ? (
        <div className="absolute flex flex-col cursor-pointer gap-1 p-2 w-[248px] top-11 bg-tertiary-0 z-10 rounded-2 shadow-hover right-0">
          <ReplaceProviderButtonV2
            buttonProps={buttonProps}
            providerNpi={npi}
            therapyType={therapyType}
          />
        </div>
      ) : null}
    </div>
  );
};

export default MobileProviderCardDropdown;
