import { Link } from 'react-router-dom';
import { useContext } from 'react';
import WithPageState from '../WithPageState';
import { ProviderLockupV2 } from '@/pages/care-team/ProviderLockupV2';
import { ProviderWithFirstAppointmentInfo, TherapyType } from '@/utils/types';
import MobileProviderCardDropdown from './MobileProviderCardDropdown';
import { MobileContext } from '@/App';

export interface ProviderCardV2Props {
  provider: ProviderWithFirstAppointmentInfo;
  active: boolean;
  therapyType: TherapyType;
}
const ProviderCardV2 = ({
  provider,
  active,
  therapyType,
}: ProviderCardV2Props) => {
  return (
    <WithPageState page="care-team">
      {({ pageState }) => {
        const { npi } = provider;
        const mobile = useContext(MobileContext);

        return (
          <div className="m-auto mb-4 border-1 border-tertiary-2 rounded-2 md:min-w-[485px]">
            {mobile && active ? (
              <div className="border-b-1 border-tertiary-2 flex p-4 items-center justify-end flex-wrap gap-y-2 md:justify-between">
                <MobileProviderCardDropdown
                  npi={npi}
                  therapyType={therapyType}
                />
              </div>
            ) : null}
            <Link to={`/care-team/${npi}`} className="no-underline">
              <ProviderLockupV2
                pageState={pageState}
                provider={provider}
                active={active}
                therapyType={therapyType}
              />
            </Link>
          </div>
        );
      }}
    </WithPageState>
  );
};

export default ProviderCardV2;
