import { Dispatch, useEffect, useRef } from 'react';
import { validate } from 'email-validator';
import actions, { Action } from '../state/actions';
import TextInput from '../components/TextInput';
import Text from '../components/Text';
import { UnauthenticatedUi } from '../utils/types';
import Button from '../components/Button';
import SupportLink from '../components/SupportLink';
import fetch from '@/utils/fetch';

const Login = ({
  state,
  dispatch,
}: {
  state: Extract<UnauthenticatedUi, { status: 'login' }>;
  dispatch: Dispatch<Action>;
}) => {
  const { email, delivered, emailInvalid } = state;
  const onSubmit = () => {
    if (validate(email)) {
      dispatch(actions.login.setEmailDelivered(true));
      window.scrollTo({ top: 0, behavior: 'smooth' });
      const loc = window.location;
      fetch(`/magic_token`, {
        method: 'POST',
        body: JSON.stringify({
          email,
          baseUrl:
            loc.protocol + '//' + loc.hostname + loc.pathname + loc.search,
        }),
        headers: { 'Content-Type': 'application/json' },
      }).catch((e) => console.error(e));
    } else {
      dispatch(actions.login.setEmailInvalid(true));
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current && !delivered) {
      inputRef.current.focus();
    }
  }, [inputRef.current, delivered]);

  useEffect(() => {
    if (validate(email)) {
      dispatch(actions.login.setEmailInvalid(false));
    }
  }, [email, emailInvalid]);

  const easeOut = 'transition ease-out opacity-0 duration-1000';
  const easeIn = 'transition ease-in opacity-100 duration-1000';

  return (
    <>
      <div
        className={`${
          delivered ? easeOut : 'z-10'
        } flex w-full align-center justify-center`}
      >
        <div className="space-y-6 w-1/2 min-w-[250px]">
          <Text.Serif.H1 className="flex justify-center align-center">
            Sign in
          </Text.Serif.H1>
          <Text.P className="text-center">
            To sign in, enter the email address where you receive messages from
            Rula — no password needed.
          </Text.P>
          <Text.P className="text-center">
            <Text.P.Inline.Bold>Having trouble?</Text.P.Inline.Bold>{' '}
            <a
              href="https://patientsupport.rula.com/hc/en-us/articles/22400080472603-Logging-into-the-Rula-patient-portal"
              className="text-primary-3 no-underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here
            </a>{' '}
            for detailed instructions.
          </Text.P>
          <div className="space-y-4">
            <div className="flex flex-col space-y-2">
              <Text.Small.Bold>Email</Text.Small.Bold>
              <TextInput
                value={email}
                state={emailInvalid ? 'error' : ''}
                onEnter={onSubmit}
                onChange={(email) => dispatch(actions.login.setEmail(email))}
                ref={inputRef}
              />
            </div>
            {emailInvalid && (
              <p className="rounded border-warning-1 border-1 bg-opacity-10 bg-warning-1 text-warning-1 p-3 text-center">
                Please enter a valid email address.
              </p>
            )}
            <Button
              variant="secondary"
              state={emailInvalid ? 'disabled' : ''}
              onClick={onSubmit}
            >
              Continue with email
            </Button>
          </div>
        </div>
      </div>
      <div className="absolute text-center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[95%]">
        <div className={delivered ? easeIn : 'opacity-0'}>
          <div className="space-y-6">
            <Text.Serif.H1 className="font-bold flex justify-center">
              Please check your inbox
            </Text.Serif.H1>
            <div className="bg-secondary-3 bg-opacity-50 rounded p-2 space-y-4">
              <Text.P>
                Great! Please check your inbox for a link to sign in.
              </Text.P>
              <Text.P>
                Didn't receive a link? You can only sign in using the email
                associated with your Rula account.{' '}
                <a
                  className="text-primary-3 no-underline"
                  onClick={() =>
                    dispatch(actions.login.setEmailDelivered(false))
                  }
                >
                  Try another email
                </a>{' '}
                or contact <SupportLink />
              </Text.P>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
