import getCookies from './cookies';
import { JSONObject } from './types';

type JSONRequestInit = Omit<RequestInit, 'method' | 'body'> &
  (
    | {
        method: 'POST' | 'PUT';
        body: JSONObject;
      }
    | {
        method: 'GET' | 'DELETE';
      }
  );

export type Fetch = {
  (input: RequestInfo | URL, init?: RequestInit | undefined): Promise<Response>;
  json(input: RequestInfo, jsonInit: JSONRequestInit): Promise<any>;
};

const f: Fetch = (
  input: RequestInfo | URL,
  init?: RequestInit | undefined
): Promise<Response> => {
  return window
    ._fetch(input, {
      ...(init || {}),
      credentials: 'include',
      headers: {
        ...((init || {}).headers || {}),
        'X-Csrf-Token': getCookies()['__Host-csrf'],
      },
    })
    .then((r) => {
      if (r.status >= 400) {
        throw r;
      }
      return r;
    });
};

f.json = (input: RequestInfo, jsonInit: JSONRequestInit): Promise<any> => {
  const init = { ...jsonInit } as RequestInit;
  if ('body' in jsonInit) {
    init.body = JSON.stringify(jsonInit.body);
    init.headers = {
      ...(init.headers || {}),
      'Content-Type': 'application/json',
    };
  }
  return f(input, init).then((r) => r.json());
};

export default f;
