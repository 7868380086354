import actions from '@/state/actions';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { MobileContext, StateContext } from '@/App';
import Text from '@/components/Text';
import { AnalyticsEvent } from '@/utils/types';
import useData from '@/state/use-data';
import trackEvent from '@/utils/track';

const CardStatusBanner = () => {
  const { dispatch } = useContext(StateContext);
  const { WithData, data } = useData(['patientData']);
  const mobile = useContext(MobileContext);
  const TextComponent = mobile ? Text.Small : Text.P;
  const UnderlineTextComponent = mobile
    ? Text.Small.Bold.Underline
    : Text.P.Inline.Bold.Underline;

  return (
    <WithData data={data}>
      {({ patientData }) => {
        const { patient_record_uuid } = patientData;
        const clickCardStatusBanner: AnalyticsEvent = {
          product_area: 'Billing',
          name: 'Card_status_banner_click',
          trigger: 'Interaction',
          patient_record_uuid,
          metadata: {},
        };
        return (
          <TextComponent className="text-center text-warning-1 p-1">
            Your credit card on file is either missing or invalid. Please{' '}
            <UnderlineTextComponent>
              <Link
                to="/profile"
                onClick={() => {
                  dispatch(actions.profile.setCcEditMode('edit'));
                  trackEvent(clickCardStatusBanner);
                }}
              >
                <u>update your credit card</u>
              </Link>
            </UnderlineTextComponent>{' '}
            to continue using Rula.
          </TextComponent>
        );
      }}
    </WithData>
  );
};
export default CardStatusBanner;
