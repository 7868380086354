import { useContext, useEffect, useMemo } from 'react';
import { StateContext } from '@/App';
import {
  AnalyticsEvent,
  CreditCardDetails,
  Editable,
  EditableCreditCardDetails,
  isLoaded,
  PatientData,
  ServerData,
} from '@/utils/types';
import actions from '@/state/actions';
import CardStatusBanner from '@/components/CardStatusBanner';
import dayjs from 'dayjs';
import trackEvent from './track';
import { useFlagCheck } from './use-feature-flags';

const checkCreditCardValid = (
  creditCard: ServerData<Editable<CreditCardDetails, EditableCreditCardDetails>>
) => {
  const currentDate = dayjs();
  if (isLoaded(creditCard)) {
    if (
      Object.keys(creditCard).length &&
      creditCard.expiration_year !== undefined &&
      creditCard.expiration_month !== undefined
    ) {
      const cardExpirationDate = dayjs()
        .year(parseInt(`20${creditCard.expiration_year}`))
        .month(parseInt(creditCard.expiration_month) - 1);
      return (
        cardExpirationDate.isAfter(currentDate) ||
        cardExpirationDate.isSame(currentDate, 'month')
      );
    }
  }
  return false;
};

// Custom hook to manage status banner (if applicable) and provides
// quick access for whether credit card is valid from context
export const useCreditCard = (manageStatusBanner = false) => {
  const { state, dispatch } = useContext(StateContext);
  const {
    creditCard,
    patientData,
  }: {
    creditCard: ServerData<
      Editable<CreditCardDetails, EditableCreditCardDetails>
    >;
    patientData: ServerData<PatientData>;
  } = state.data;
  const creditCardIsValid = useMemo(
    () => checkCreditCardValid(creditCard),
    [creditCard]
  );
  const { isAllowed } = useFlagCheck();
  const hideCreditCardBanner = isAllowed({
    patientPortalHideCcStatusBanner: true,
  });

  useEffect(() => {
    if (manageStatusBanner) {
      // logic for invalid credit card banner
      if (
        creditCard.loadingState === 'done' &&
        creditCard.editState.mode !== 'edit'
      ) {
        if (creditCardIsValid) {
          dispatch(actions.billing.setCardStatus('present'));
          if (state.ui.banner) {
            setTimeout(() => {
              dispatch(actions.setBanner(null));
            }, 1000);
          }
        } else {
          dispatch(actions.billing.setCardStatus('not present'));
          // we want to hide the CC status banner for some small percentage of test users
          // e.g. for Rula content creation team so they can record videos of the portal on sandbox
          if (!hideCreditCardBanner) {
            dispatch(
              actions.setBanner({
                children: <CardStatusBanner />,
              })
            );

            if (patientData.loadingState === 'done') {
              const patient_record_uuid = patientData.patient_record_uuid;
              const BannerRendered: AnalyticsEvent = {
                product_area: 'Billing',
                name: 'Missing_Credit_Card_Banner_viewed',
                trigger: 'Page load',
                patient_record_uuid,
                metadata: {},
              };
              trackEvent(BannerRendered);
            }
          }
        }
      }
    }
  }, [creditCard]);

  return {
    creditCardIsValid,
    hideCreditCardBanner,
  };
};
