import Icon, { IconProps } from './Icon';

const Replace = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M13 11.8889H1M1 11.8889L4 8.77778M1 11.8889L4 15M1 4.11111H13M13 4.11111L10 1M13 4.11111L10 7.22222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(6,5)"
      />
    </Icon>
  );
};

export default Replace;
